@tailwind base;
@tailwind components;
@tailwind utilities;

.app__navigation-dot {
  transition: background-color 0.2s ease-in-out;
  @media screen and (min-width: 2000px) {
    width: 20px;
    height: 20px;
  }
}

.blur {
  position: absolute;
  border-radius: 50%;
  filter: blur(212px);
  z-index: -9;
  filter: blur(72px);
}

.app_header {
  background-image: linear-gradient(
    to right bottom,
    rgb(209, 172, 232),
    rgb(253, 250, 254)
  );
}

.menuItems {
  /* color: black; */
  text-shadow: 4px 4px 25px rgb(217, 217, 91), 4px 4px 25px rgb(217, 217, 91),
    4px 4px 15px rgb(217, 217, 91);
}

.menu-a-1 {
  position: absolute;
  bottom: 98%;
  left: 44%;
}

.menu-a-2 {
  position: absolute;
  top: 42%;
  left: 95%;
}
.menu-a-3 {
  position: absolute;
  top: 42%;
  right: 95%;
}
.menu-a-4 {
  position: absolute;
  top: 90%;
  left: 36%;
}

/* Skills Section */
.skill__bg {
  background-image: linear-gradient(
    180deg,
    rgb(253, 250, 254),
    rgba(209, 172, 232, 0.5),
    rgb(253, 250, 254)
  );
}
/* Skills - build */
.build_bg {
  background-image: linear-gradient(
    180deg,
    rgb(253, 250, 254),
    rgba(209, 172, 232, 0.6),
    rgb(253, 250, 254)
  );
  /* clip-path: polygon(0 0, 100% 0%, 100% 100%, 15% 100%); */
  clip-path: polygon(0 0, 100% 0%, 85% 100%, 0 100%);
  background-size: cover;
  background-repeat: repeat;
}
.build_card {
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
  background-color: rgb(253, 250, 254);
}
.buildImage_bg {
  background-size: cover;
  height: 80px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 85%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%);
  /* background-color: rgba(211, 218, 125, 0.6); */
  background-color: hsla(210, 100%, 65%, 0.3);
}
.buildTitles {
  box-shadow: 0 0 15px rgb(209, 172, 232);
}

/* skills-tech */
.techTitles {
  box-shadow: 0 0 15px rgb(148, 142, 152);
}

/* Hire Me Section */
/* form */
.form__input {
  font-size: 1rem;
  background-color: rgb(247, 243, 243);
  /* border: 1px solid rgb(211, 218, 125); */
  /* border-bottom: 3px solid transparent; */
  /* box-shadow: 0 0 5px rgb(211, 218, 125); */
  width: 90%;
  padding: 8px 0;
  transition: all 0.3s;
  text-align: center;
}
.form__input:focus {
  outline: none;
  box-shadow: 0 1rem 2rem rgba(black, 0.1);
  border-bottom: 4px solid rgb(211, 218, 125);
}

.form__label {
  font-size: 1rem;
  font-weight: 700;
  margin-left: 2rem;
  margin-top: 0.3rem;
  display: block;
  transition: all 0.3s;
  color: gray;
}
.form__input:placeholder-shown + .form__label {
  opacity: 0;
  visibility: hidden;
  transform: translateY(-4rem);
}

.btn {
  padding: 8px 0;
  width: 90%;
  transition: all 0.2s;
  background-color: rgba(211, 218, 125, 0.6);
  color: black;
  box-shadow: 0px 10px 30px 3px rgb(174, 174, 170);
  border: 1px solid rgb(255, 255, 255);
}

.btn:hover {
  transform: translateY(-3px);
  background-color: rgb(211, 218, 125);
  color: black;
  transform: translateY(-2px);
}
/* contactbg 1 text */
.text-shadow {
  text-shadow: 0 0 5px rgb(73, 73, 73), 0 0 5px rgb(73, 73, 73),
    0 0 5px rgb(73, 73, 73);
}

.contact_bg_1 {
  /* width: 400px; */
  background-image: linear-gradient(
    180deg,
    rgb(253, 250, 254),
    rgba(209, 172, 232, 0.8),
    rgb(253, 250, 254)
  );
  clip-path: polygon(0 0, 80% 0%, 100% 100%, 0 100%);
  background-size: cover;
  background-repeat: repeat;
  background-position: bottom;
}

.contact_bg_3 {
  background-image: linear-gradient(
    180deg,
    rgb(253, 250, 254),
    rgba(209, 172, 232, 0.8),
    rgb(253, 250, 254)
  );
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 25% 100%);
  background-size: cover;
  background-repeat: repeat;
}

/* Projects Section */
.project__bg,
.testimonial__bg,
.hireme__bg {
  background-image: linear-gradient(
    180deg,
    rgb(253, 250, 254),
    rgba(209, 172, 232, 0.8),
    rgb(253, 250, 254)
  );
  background-size: cover;
  background-repeat: repeat;
}

.projects_showcase {
  height: 300px;
}
.features {
  font-size: 16px;
}
.purpose {
  font-size: 17px;
}

/* hire me section */

.hiremebtn {
  padding: 8px 0;
  width: 90%;
  transition: all 0.2s;
  background-color: rgba(211, 218, 125, 0.6);
  color: black;
  box-shadow: 0px 10px 30px 3px rgb(99, 99, 99);
  border: 1px solid rgb(255, 255, 255);
  position: relative;
  margin: 20px 0;

}
.hiremebtn:hover {
  transform: translateY(-3px);
  background-color: rgb(211, 218, 125);
  color: black;
  transform: translateY(-2px);
}

/* hover feature */
/* left */
.lefthover:after {
  display: block;
  content: "";
  border-bottom: solid 4px white;
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.lefthover:after {
  transform-origin: 100% 50%;
}

.lefthover:hover:after {
  transform: scaleX(1);
  transform-origin: 0% 50%;
}
/* center */
.centerhover:after,
.centerhover:before {
  display: block;
  content: "";
  border-bottom: solid 2px hsl(300, 100%, 60.4%);
  transform: scaleX(0);
  transition: transform 250ms ease-in-out;
}
.centerhover:before {
  position: absolute;
  bottom: 3em;
  left: 0;
  width: 100%;
}
.centerhover:hover:after {
  transition-delay: 150ms;
}

.centerhover:hover:after,
.centerhover:hover:before {
  transform: scaleX(1);
}

/* bouncing button */
.shared-icons {
  transition: all 0.3s linear;
}

.shared-icons-div:hover .shared-icons {
  transform: scale(1.5);
}
